/**
 * src/actions/adminUserRegistration.js
 *
 * このファイルは、管理者ユーザーに関連するReduxのアクションを定義します。
 * 主に以下の機能を提供します：
 *   - 管理者ユーザー一覧の取得
 *   - 新規管理者の登録
 *   - 既存管理者の削除
 *
 * 各アクションは非同期のAPIリクエストを行い、その結果に基づいてReduxのステートを更新します。
 * APIエンドポイントのURLは環境変数から取得し、適切なHTTPリクエストを実行します。
 */

import axios from 'axios';
import {
  GET_ADMIN_USERS_SUCCESS,
  GET_ADMIN_USERS_FAILURE,
  REGISTER_ADMIN_SUCCESS,
  REGISTER_ADMIN_FAILURE,
  DELETE_ADMIN_SUCCESS,
  DELETE_ADMIN_FAILURE,
  RESET_ADMIN_PASSWORD_SUCCESS,
  RESET_ADMIN_PASSWORD_FAILURE,
} from './types';

// 管理者ユーザー一覧の取得
export const getAdminUsers = () => async (dispatch, getState) => {
  const { adminAccessToken } = getState().adminSession; // 現在のアクセストークンを取得
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/auth/admin_user_registration/get_admin_users`, {
      headers: { Authorization: `Bearer ${adminAccessToken}` }, // トークンを添付
    });
    dispatch({
      type: GET_ADMIN_USERS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: GET_ADMIN_USERS_FAILURE,
      payload: error.response.data.message,
    });
  }
};

// 新規管理者の登録
export const registerAdmin = (username, password) => async (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    const { adminAccessToken } = getState().adminSession;
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/admin_user_registration/register_admin`,
        { username, password },
        { headers: { Authorization: `Bearer ${adminAccessToken}` } }
      );
      dispatch({
        type: REGISTER_ADMIN_SUCCESS,
        payload: response.data,
      });
      resolve(response.data);
    } catch (error) {
      dispatch({
        type: REGISTER_ADMIN_FAILURE,
        payload: error.response.data.message,
      });
      reject(error.response.data.message);
    }
  });
};

// 管理者の削除
export const deleteAdmin = (adminUserId) => async (dispatch, getState) => {
  const { adminAccessToken } = getState().adminSession;
  try {
    await axios.delete(`${process.env.REACT_APP_API_URL}/auth/admin_user_registration/delete_admin`, {
      headers: { Authorization: `Bearer ${adminAccessToken}` },
      data: { userid: adminUserId },
    });
    dispatch({
      type: DELETE_ADMIN_SUCCESS,
      payload: adminUserId,
    });
  } catch (error) {
    dispatch({
      type: DELETE_ADMIN_FAILURE,
      payload: error.response.data.message,
    });
  }
};

// パスワードリセットアクション
export const resetAdminPassword = (adminUserId, newPassword) => async (dispatch, getState) => {
  const { adminAccessToken } = getState().adminSession;
  try {
    await axios.post(
      `${process.env.REACT_APP_API_URL}/auth/admin_user_registration/reset_admin_password`,
      { userid: adminUserId, password: newPassword },
      { headers: { Authorization: `Bearer ${adminAccessToken}` } }
    );
    dispatch({
      type: RESET_ADMIN_PASSWORD_SUCCESS,
      payload: adminUserId, // 成功した場合、ユーザーIDをペイロードに含める
    });
  } catch (error) {
    dispatch({
      type: RESET_ADMIN_PASSWORD_FAILURE,
      payload: error.response.data.message,
    });
  }
};
