/**
 * AdminUserManagementPage コンポーネント
 *
 * このコンポーネントは、管理者ユーザーの管理画面を提供します。管理者ユーザーの一覧表示、
 * 新規管理者の追加、および既存管理者の削除機能を含みます。
 *
 * 主な機能:
 * - 管理者ユーザーの一覧表示: 登録されている管理者ユーザーの情報（ID、ユーザー名、アクティブ状態、
 *   作成日時、更新日時）をテーブル形式で表示します。
 * - 新規管理者の追加: 「新規追加」ボタンをクリックすると、新しい管理者を追加するためのモーダルが表示され、
 *   ユーザー名とパスワードを入力して新規管理者を登録できます。
 * - 管理者の削除: 各管理者の左側にあるチェックボックスを使用して管理者を選択し、「削除」ボタンをクリックすると、
 *   選択した管理者を削除できます。
 *
 * 使用される主な技術・ライブラリ:
 * - React: UI の構築に使用される JavaScript ライブラリ。
 * - Redux: アプリケーションの状態管理。
 * - Emotion: CSS-in-JS スタイリングライブラリ。
 * - react-router-dom: React アプリケーションのルーティングを管理。
 * - axios: HTTPクライアント、API呼び出しに使用。
 */

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { css } from '@emotion/react';
import NavigationBar from '../components/internal/NavigationBar';
import Typography from '../components/shared/Typography';
import Button from '../components/shared/button/Button';
import Logo from '../components/internal/Logo';
import { getAdminUsers, registerAdmin, deleteAdmin, resetAdminPassword } from '../actions/adminUserRegistration';
import AdminUserRegistrationModal from '../components/admin_management/AdminUserRegistrationModal'; // 新しい管理者追加用モーダルのインポート

function AdminUserManagementPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAdminAuthenticated = useSelector((state) => state.adminSession.isAdminAuthenticated);
  const currentAdminId = useSelector((state) => state.adminSession.adminId); // 現在の管理者のIDを取得
  const adminUsers = useSelector((state) => state.adminUserRegistration.adminUsers);
  const [selectedUserIds, setSelectedUserIds] = useState(new Set());
  const [showRegistrationModal, setShowRegistrationModal] = useState(false); // 管理者追加用モーダルの状態管理
  const [modalMode, setModalMode] = useState('register');

  useEffect(() => {
    if (!isAdminAuthenticated) {
      navigate('/login');
    } else {
      dispatch(getAdminUsers());
    }
  }, [isAdminAuthenticated, navigate, dispatch]);

  // チェックボックスの状態を管理するハンドラ
  const handleCheckboxChange = (userId) => {
    setSelectedUserIds((prevSelectedUserIds) => {
      const newSelectedUserIds = new Set(prevSelectedUserIds);
      if (newSelectedUserIds.has(userId)) {
        newSelectedUserIds.delete(userId);
      } else {
        newSelectedUserIds.add(userId);
      }
      return newSelectedUserIds;
    });
  };

  const handleResetPassword = () => {
    // 選択された管理者がいない場合のチェック
    if (selectedUserIds.size === 0) {
      alert('パスワードをリセットする管理者を選択してください。');
      return;
    }
    if (selectedUserIds.size !== 1) {
      alert('パスワードをリセットする管理者を1名選択してください。');
      return;
    }
    setModalMode('reset'); // モーダルをパスワードリセットモードに設定
    setShowRegistrationModal(true); // モーダルを表示
  };

  // 選択された管理者を削除するハンドラ
  const handleDeleteSelected = () => {
    // 選択された管理者がいない場合のチェック
    if (selectedUserIds.size === 0) {
      alert('削除する管理者を選択してください。');
      return;
    }

    // 自分自身のアカウントを選択している場合のチェック
    if (selectedUserIds.has(currentAdminId)) {
      alert('自分自身のアカウントは削除できません。');
      return;
    }

    // 削除の確認ダイアログ
    if (window.confirm('選択した管理者を削除してもよろしいですか？')) {
      selectedUserIds.forEach((userId) => {
        dispatch(deleteAdmin(userId));
      });
      setSelectedUserIds(new Set()); // 選択状態をリセット
      refreshAdminList(); // 管理者リストを更新
    }
  };

  // 管理者ユーザー一覧の表示
  const refreshAdminList = () => {
    dispatch(getAdminUsers());
  };

  return (
    <div>
      <NavigationBar />
      <div css={styles.container}>
        <Logo alt="GPTdex Logo" css={styles.logoStyle} />
        <Typography variant="title" style={styles.title}>
          管理者ユーザー管理
        </Typography>
        <div css={styles.actions}>
          <Button
            variant="primary"
            size="minimum"
            textAlign="center"
            onClick={() => {
              setShowRegistrationModal(true);
              setModalMode('register');
            }}
          >
            新規追加
          </Button>
          <Button variant="primary" size="minimum" textAlign="center" onClick={handleResetPassword}>
            パスワードリセット
          </Button>
          <Button variant="primary" size="minimum" textAlign="center" onClick={handleDeleteSelected}>
            削除
          </Button>
        </div>
        <div css={tableContainerStyle}>
          <table css={tableStyle}>
            <thead>
              <tr>
                <th css={styles.checkboxColumn}></th>
                <th>ID</th>
                <th>ユーザー名</th>
                <th>アクティブ</th>
                <th>作成日時</th>
                <th>更新日時</th>
              </tr>
            </thead>
            <tbody>
              {adminUsers.map((user) => (
                <tr key={user.userid}>
                  <td css={styles.checkboxColumn}>
                    <input
                      type="checkbox"
                      css={checkboxStyle}
                      checked={selectedUserIds.has(user.userid)}
                      onChange={() => handleCheckboxChange(user.userid)}
                    />
                  </td>
                  <td>{user.userid}</td>
                  <td>{user.username}</td>
                  <td>{user.is_active ? 'はい' : 'いいえ'}</td>
                  <td>{user.created_at}</td>
                  <td>{user.updated_at}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {showRegistrationModal && (
        <AdminUserRegistrationModal
          isOpen={showRegistrationModal}
          onClose={() => setShowRegistrationModal(false)}
          onSubmit={(username, password, setError) => {
            if (modalMode === 'register') {
              // 新規管理者追加のAPI呼び出し
              return new Promise((resolve, reject) => {
                dispatch(registerAdmin(username, password))
                  .then((response) => {
                    setShowRegistrationModal(false); // 成功時にモーダルを閉じる
                    refreshAdminList(); // 管理者リストを更新
                    resolve(response);
                  })
                  .catch((error) => {
                    setError(error); // 失敗時にエラーメッセージをセット
                    reject(error);
                  });
              });
            } else if (modalMode === 'reset') {
              // 管理者パスワードリセットのAPI呼び出し
              return new Promise((resolve, reject) => {
                dispatch(resetAdminPassword(username, password))
                  .then((response) => {
                    setShowRegistrationModal(false); // 成功時にモーダルを閉じる
                    refreshAdminList(); // 管理者リストを更新
                    resolve(response);
                  })
                  .catch((error) => {
                    setError(error); // 失敗時にエラーメッセージをセット
                    reject(error);
                  });
              });
            }
          }}
          mode={modalMode}
          selectedUserId={Array.from(selectedUserIds)[0]} // 選択されたユーザーIDを渡す
        />
      )}
    </div>
  );
}

export default AdminUserManagementPage;

// スタイルの定義
const styles = {
  container: css`
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  title: css`
    margin-bottom: 40px;
  `,
  actions: css`
    display: flex;
    gap: 10px;
    width: 100%;
    margin-bottom: 20px;
    justify-content: flex-start;
  `,
  checkboxColumn: css`
    width: 50px; // チェックボックスカラムの幅を設定
  `,
  // その他の必要なスタイル
};

const tableContainerStyle = css`
  overflow-x: auto; // コンテナの幅を超えるテーブルに対して水平スクロールバーを表示
  margin: 20px 0;
  width: 100%;
`;

const tableStyle = css`
  min-width: 800px;
  border-collapse: collapse;
  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
    &:nth-child(1) {
      // チェックボックス
      width: 40px;
      text-align: center;
    }
    &:nth-child(2) {
      // ID
      width: 40px;
    }
    &:nth-child(3) {
      // ユーザー名
      width: 160px;
    }
    &:nth-child(4) {
      // アクティブ
      width: 80px;
    }
    &:nth-child(5),
    &:nth-child(6) {
      // 作成日時、更新日時の列
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100px;
      width: 100px;
    }
  }
  th {
    background-color: #f8f8f8;
  }
  tr:hover {
    background-color: #f2f2f2;
  }
`;

const checkboxStyle = css`
  transform: scale(1.5);
  cursor: pointer; // チェックボックスにカーソルが乗ったときのカーソルを指にする
`;
