/*
  src/App.js

  このファイルはReactアプリケーションのルートコンポーネントを定義します。
  ここでは、react-router-domを使って、アプリケーションのルーティングを設定しています。
  アプリケーションの各ページに対応するルートが設定されており、
  ブラウザのURLに応じて表示するページが切り替わります。
*/
import React, { useEffect } from 'react';
import { Global, css } from '@emotion/react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import store from './store';
import { adminLogout, refreshAdminAccessToken, getAdminProfile, validateToken } from './actions/adminSession';
import LoginPage from './pages/LoginPage';
import DashboardPage from './pages/DashboardPage';
import AdminUserManagementPage from './pages/AdminUserManagementPage';
import UserManagementPage from './pages/UserManagementPage';

function App() {
  // コンポーネントがマウントされたとき（アプリ起動, ページ読み込み）に一度だけ実行
  useEffect(() => {
    // アプリ起動時にトークンの有効性を確認
    const checkTokenValidity = async () => {
      const accessToken = localStorage.getItem('gptdexAdminAccessToken');
      const refreshToken = localStorage.getItem('gptdexAdminRefreshToken');

      if (!accessToken && !refreshToken) {
        // アクセストークンもリフレッシュトークンもない場合、ログアウト処理
        store.dispatch(adminLogout());
      } else if (accessToken) {
        try {
          // アクセストークンの有効性を確認
          const tokenValid = await store.dispatch(validateToken(accessToken));
          if (tokenValid) {
            // アクセストークンが有効な場合、管理者情報を取得
            store.dispatch(getAdminProfile(accessToken));
          } else {
            // トークンが無効の場合、ログアウト処理
            store.dispatch(adminLogout());
          }
        } catch (error) {
          if (refreshToken) {
            // トークンが無効の場合、リフレッシュトークンを使用して更新
            store
              .dispatch(refreshAdminAccessToken(refreshToken))
              .then((isValidToken) => {
                if (!isValidToken) {
                  // リフレッシュに失敗した場合、ログアウト処理
                  store.dispatch(adminLogout());
                }
              })
              .catch(() => {
                // リフレッシュで例外発生した場合、ログアウト処理
                store.dispatch(adminLogout());
              });
          } else {
            // リフレッシュトークンがない場合、ログアウト処理
            store.dispatch(adminLogout());
          }
        }
      }
    };

    checkTokenValidity();
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <div className="App">
          <Global styles={globalStyle} />
          <Routes>
            <Route path="/" element={<DashboardPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/admin-users" element={<AdminUserManagementPage />} />
            <Route path="/users" element={<UserManagementPage />} />
          </Routes>
        </div>
      </Router>
    </Provider>
  );
}

export default App;

// Emotionでのスタイル定義
const globalStyle = css`
  html {
    font-size: 18px;
  }
  body {
    margin: 0;
    font-size: 1rem;
    font: 'Noto Sans JP', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`;
