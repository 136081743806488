/**
 * src/actions/types.js
 *
 * このモジュールは、アプリケーション内で使用されるReduxアクションタイプを定義します。
 * 各アクションタイプは、アプリケーションの異なる部分で発生する様々なイベントを表します。
 *
 * 使用上の注意:
 * - このファイルに追加されるアクションタイプは、明確で一意的な名前を持つべきです。
 * - 新しい機能を実装する際には、関連するアクションタイプをここに追加します。
 * - アクションタイプは、アクションクリエーターとリデューサーで使用されます。
 */

// 管理者認証関連のアクションタイプ
export const ADMIN_LOGIN_SUCCESS = 'ADMIN_LOGIN_SUCCESS';
export const ADMIN_LOGIN_FAILURE = 'ADMIN_LOGIN_FAILURE';
export const ADMIN_LOGOUT = 'ADMIN_LOGOUT';
export const ADMIN_REFRESH_TOKEN_SUCCESS = 'ADMIN_REFRESH_TOKEN_SUCCESS';
export const ADMIN_PROFILE_SUCCESS = 'ADMIN_PROFILE_SUCCESS';
export const ADMIN_PROFILE_FAILURE = 'ADMIN_PROFILE_FAILURE';
export const VALIDATE_TOKEN_SUCCESS = 'VALIDATE_TOKEN_SUCCESS';
export const VALIDATE_TOKEN_FAILURE = 'VALIDATE_TOKEN_FAILURE';

// 管理者管理のアクションタイプ
export const GET_ADMIN_USERS_SUCCESS = 'GET_ADMIN_USERS_SUCCESS';
export const GET_ADMIN_USERS_FAILURE = 'GET_ADMIN_USERS_FAILURE';
export const REGISTER_ADMIN_SUCCESS = 'REGISTER_ADMIN_SUCCESS';
export const REGISTER_ADMIN_FAILURE = 'REGISTER_ADMIN_FAILURE';
export const DELETE_ADMIN_SUCCESS = 'DELETE_ADMIN_SUCCESS';
export const DELETE_ADMIN_FAILURE = 'DELETE_ADMIN_FAILURE';
export const RESET_ADMIN_PASSWORD_SUCCESS = 'RESET_ADMIN_PASSWORD_SUCCESS';
export const RESET_ADMIN_PASSWORD_FAILURE = 'RESET_ADMIN_PASSWORD_FAILURE';

// ユーザー管理のアクションタイプ
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILURE = 'REGISTER_USER_FAILURE';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const EDIT_USER_FAILURE = 'EDIT_USER_FAILURE';
export const RESET_USER_PASSWORD_SUCCESS = 'RESET_USER_PASSWORD_SUCCESS';
export const RESET_USER_PASSWORD_FAILURE = 'RESET_USER_PASSWORD_FAILURE';
