/**
 * src/actions/adminSession.js
 *
 * このファイルは、管理者セッションに関連するReduxのアクションクリエーターを定義します。
 * 主に以下の機能を提供します：
 *   - 管理者のログイン
 *   - 管理者のログアウト
 *   - アクセストークンのリフレッシュ
 *   - 管理者プロファイル情報の取得
 *   - トークンの有効性の確認
 *
 * 各アクションは非同期のAPIリクエストを行い、その結果に基づいてReduxのステートを更新します。
 * APIエンドポイントのURLは環境変数から取得し、適切なHTTPリクエストを実行します。
 *
 * 使用上の注意:
 * - APIエンドポイントのURLは、バックエンドの設定に合わせて適宜調整してください。
 * - エラーハンドリングは、ユーザーフレンドリーなフィードバックを提供するために重要です。
 * - 各アクションクリエーターは非同期操作を含む場合、Redux Thunkを使用しています。
 */

import axios from 'axios';
import {
  ADMIN_LOGIN_SUCCESS,
  ADMIN_LOGIN_FAILURE,
  ADMIN_LOGOUT,
  ADMIN_REFRESH_TOKEN_SUCCESS,
  ADMIN_PROFILE_SUCCESS,
  ADMIN_PROFILE_FAILURE,
  VALIDATE_TOKEN_SUCCESS,
  VALIDATE_TOKEN_FAILURE,
} from './types';

// 管理者ログインリクエストを送信するアクション
export const adminLogin = (username, password) => async (dispatch) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/admin_session/login`, {
      username,
      password,
    });

    // トークンのlocalStorageへの保存
    localStorage.setItem('gptdexTaxAdminAccessToken', response.data.access_token);
    localStorage.setItem('gptdexTaxAdminRefreshToken', response.data.refresh_token);

    dispatch({
      type: ADMIN_LOGIN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    const errorMessage = error.response
      ? error.response.data.message
      : '管理者ログイン中にエラーが発生しました。もう一度お試しください。';
    dispatch({
      type: ADMIN_LOGIN_FAILURE,
      payload: errorMessage,
    });
  }
};

// 管理者ログアウトリクエストを送信するアクション
export const adminLogout = () => async (dispatch) => {
  // トークンのlocalStorageからの削除
  localStorage.removeItem('gptdexTaxAdminAccessToken');
  localStorage.removeItem('gptdexTaxAdminRefreshToken');

  // ログアウトアクションのディスパッチ
  dispatch({
    type: ADMIN_LOGOUT,
  });
};

// アクセストークンリフレッシュのリクエストを送信するアクション
export const refreshAdminAccessToken = (refreshToken) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/auth/admin_session/refresh_token`,
      {},
      {
        headers: {
          Authorization: `Bearer ${refreshToken}`,
        },
      }
    );

    if (response.data && response.data.access_token) {
      localStorage.setItem('gptdexTaxAdminAccessToken', response.data.access_token); // localStorageにも保存
      dispatch({ type: ADMIN_REFRESH_TOKEN_SUCCESS, payload: response.data.access_token });
    } else {
      // 新しいトークンが得られなかった場合、ログアウト処理を実行
      dispatch(adminLogout());
    }
  } catch (err) {
    console.error('Error refreshing admin access token:', err);
    // 新しいトークンが得られなかった場合、ログアウト処理を実行
    dispatch(adminLogout());
  }
};

// 管理者プロファイル情報取得のアクション
export const getAdminProfile = (accessToken) => async (dispatch) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/auth/admin_session/profile`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    dispatch({
      type: ADMIN_PROFILE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_PROFILE_FAILURE,
      payload: error.response ? error.response.data.message : '管理者情報の取得に失敗しました。',
    });
  }
};

// トークンの有効性確認のアクション
export const validateToken = (accessToken) => async (dispatch) => {
  try {
    await axios.get(`${process.env.REACT_APP_API_URL}/auth/admin_session/validate_token`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    dispatch({
      type: VALIDATE_TOKEN_SUCCESS,
    });
    return true; // トークンが有効
  } catch (error) {
    dispatch({
      type: VALIDATE_TOKEN_FAILURE,
      payload: error.response ? error.response.data.message : 'トークンの有効性の確認に失敗しました。',
    });
    return false; // トークンが無効
  }
};
