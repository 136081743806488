/**
 * src/reducers/adminUserRegistration.js
 *
 * このファイルは、管理者ユーザー関連のReduxのリデューサーを定義します。
 * リデューサーは、アクションのタイプに基づいてアプリケーションのステートを更新します。
 *
 * 以下のステート更新が含まれます：
 *   - 管理者ユーザー一覧の取得成功・失敗
 *   - 新規管理者の登録成功・失敗
 *   - 既存管理者の削除成功・失敗
 *
 * ステートは初期状態として空の管理者ユーザー一覧とnullのエラーを持ちます。
 */

import {
  GET_ADMIN_USERS_SUCCESS,
  GET_ADMIN_USERS_FAILURE,
  REGISTER_ADMIN_SUCCESS,
  REGISTER_ADMIN_FAILURE,
  DELETE_ADMIN_SUCCESS,
  DELETE_ADMIN_FAILURE,
  RESET_ADMIN_PASSWORD_SUCCESS,
  RESET_ADMIN_PASSWORD_FAILURE,
} from '../actions/types';

const initialState = {
  adminUsers: [],
  error: null,
};

const adminUserRegistrationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ADMIN_USERS_SUCCESS:
      return {
        ...state,
        adminUsers: action.payload,
        error: null,
      };
    case GET_ADMIN_USERS_FAILURE:
    case REGISTER_ADMIN_FAILURE:
    case DELETE_ADMIN_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case REGISTER_ADMIN_SUCCESS:
      return {
        ...state,
        adminUsers: [
          ...state.adminUsers,
          [
            action.payload.userid,
            action.payload.username,
            action.payload.is_active,
            action.payload.created_at,
            action.payload.updated_at,
          ],
        ],
        error: null,
      };
    case DELETE_ADMIN_SUCCESS:
      return {
        ...state,
        adminUsers: state.adminUsers.filter((user) => user.admin_id !== action.payload),
        error: null,
      };
    case RESET_ADMIN_PASSWORD_SUCCESS:
      return {
        ...state,
        error: null,
      };
    case RESET_ADMIN_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default adminUserRegistrationReducer;
