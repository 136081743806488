/**
 * src/reducers/userRegistration.js
 *
 * このファイルは、ユーザー関連のReduxのリデューサーを定義します。
 * リデューサーは、アクションのタイプに基づいてアプリケーションのステートを更新します。
 *
 * 以下のステート更新が含まれます：
 *   - ユーザー一覧の取得成功・失敗
 *   - 新規ユーザーの登録成功・失敗
 *   - 既存ユーザーの削除成功・失敗
 *
 * ステートは初期状態として空のユーザー一覧とnullのエラーを持ちます。
 */
import {
  GET_USERS_SUCCESS,
  GET_USERS_FAILURE,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAILURE,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAILURE,
  RESET_USER_PASSWORD_SUCCESS,
  RESET_USER_PASSWORD_FAILURE,
} from '../actions/types';

const initialState = {
  users: [],
  error: null,
};

const userRegistrationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
        error: null,
      };
    case GET_USERS_FAILURE:
    case REGISTER_USER_FAILURE:
    case DELETE_USER_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case REGISTER_USER_SUCCESS:
      return {
        ...state,
        users: [...state.users, action.payload],
        error: null,
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        users: state.users.filter((user) => user.userid !== action.payload),
        error: null,
      };
    case EDIT_USER_SUCCESS:
      return {
        ...state,
        users: state.users.map((user) => (user.userid === action.payload.userid ? action.payload : user)),
        error: null,
      };
    case EDIT_USER_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case RESET_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        error: null,
      };
    case RESET_USER_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default userRegistrationReducer;
