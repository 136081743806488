/**
 * AdminUserRegistrationModal コンポーネント
 *
 * このコンポーネントは、新しい管理者アカウントを追加するためのモーダルを提供します。
 * ユーザーは、このモーダルを通じて新しい管理者のユーザー名とパスワードを入力し、
 * アカウントをシステムに追加することができます。
 *
 * 主な機能:
 * - 管理者アカウント追加フォーム: ユーザー名とパスワードの入力フィールドを提供し、
 *   新しい管理者アカウントの情報を収集します。
 * - エラーメッセージの表示: 入力エラーやサーバー側の問題が発生した場合、
 *   ユーザーにエラーメッセージを表示します。
 * - モーダルの開閉機能: ユーザーは「×」ボタンをクリックすることでモーダルを閉じることができます。
 *
 * Props:
 * - isOpen: モーダルの表示状態を制御します。
 * - onClose: モーダルを閉じるための関数です。
 * - onAdd: フォームの送信時に実行される関数で、新しい管理者の追加処理を行います。
 *
 * 使用される主な技術・ライブラリ:
 * - React: UI の構築に使用される JavaScript ライブラリ。
 * - Emotion: CSS-in-JS スタイリングライブラリ。
 * - react-modal: モーダル機能を提供する React コンポーネント。
 */

import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';
import Button from '../shared/button/Button';
import Input from '../shared/Input';
import Form from '../shared/Form';
import Logo from '../internal/Logo';
import Typography from '../shared/Typography';

function AdminUserRegistrationModal({ isOpen, onClose, onSubmit, mode, selectedUserId }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const adminUsers = useSelector((state) => state.adminUserRegistration.adminUsers);

  const [subTitle, setSubTitle] = useState('');
  const [submitLabel, setSubmitLabel] = useState('');
  const [usernameReadOnly, setUsernameReadOnly] = useState(false);

  useEffect(() => {
    if (mode === 'register') {
      setSubTitle('管理者追加');
      setSubmitLabel('追加');
      setUsernameReadOnly(false);
    } else if (mode === 'reset') {
      setSubTitle('パスワードリセット');
      setSubmitLabel('リセット');
      setUsernameReadOnly(true); // ユーザー名変更不可
    }
    if (selectedUserId != null) {
      const numericUserId = parseInt(selectedUserId, 10);
      const adminUser = adminUsers.find((adminUser) => adminUser.userid === numericUserId);
      if (adminUser) {
        setUsername(adminUser.username);
        setPassword(''); // パスワードは表示しない
      }
    } else {
      setUsername('');
      setPassword('');
    }
  }, [selectedUserId, adminUsers, mode]);

  const handleRegister = (e) => {
    e.preventDefault();
    onSubmit(username, password, setError)
      .then(() => {
        if (mode === 'register') {
          setUsername('');
        }
        setPassword('');
      })
      .catch(() => {
        // エラー処理は onRegister で行われる
      });
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} style={modalStyle}>
      <Form style={formStyle} onSubmit={handleRegister}>
        <Logo alt="GPTdex Logo" style={logoStyle} />
        <Button variant="close" css={closeButtonStyle} onClick={onClose}>
          ×
        </Button>
        <Typography variant="title">GPTdex</Typography>
        <Typography variant="text">{subTitle}</Typography>
        {error && <p css={errorMessageStyle}>{error}</p>}
        <Input
          readOnly={usernameReadOnly}
          style={inputStyle}
          type="text"
          placeholder="ユーザー名"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <Input
          style={inputStyle}
          type="password"
          placeholder="パスワード"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button type="submit" variant="primary" size="large" style={registrationButtonStyle}>
          {submitLabel}
        </Button>
      </Form>
    </Modal>
  );
}

export default AdminUserRegistrationModal;

// Emotionでのスタイル定義
const modalStyle = {
  content: {
    width: '90%', // モバイル時の横幅
    maxWidth: '500px', // モーダルの最大横幅
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, 0)',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    padding: '40px 10px',
    zIndex: '1001',
  },
};

const formStyle = css`
  box-shadow: none;
  padding: 0;
  @media (max-width: 400px) {
    padding: 0 20px;
  }
`;

const logoStyle = css`
  width: 100px;
  margin-bottom: 0;
`;

const inputStyle = css`
  min-width: auto;
  width: 70%;
  @media (max-width: 450px) {
    width: 90%;
  }
`;

const registrationButtonStyle = css`
  min-width: auto;
  width: calc(70% + 20px);
  @media (max-width: 450px) {
    width: calc(90% + 20px);
  }
`;

const closeButtonStyle = css`
  position: absolute;
  top: 20px;
  right: 20px;
`;

const errorMessageStyle = css`
  color: red;
  text-align: center;
  margin: 0;
`;
