/**
 * userOptions.js
 *
 * このモジュールは、ユーザー管理に関連する共通の定数とユーティリティ関数を提供します。
 * 主にユーザータイプ（authority）とサービス区分（service）のオプション、
 * およびこれらの値に基づくラベルを取得するための関数が含まれています。
 *
 * このモジュールの目的:
 * - ユーザー管理における一貫性と再利用可能性を高めるため、ユーザータイプとサービス区分に関する
 *   定義を一カ所に集中させます。
 * - ユーザータイプとサービス区分の表示用ラベルを取得するためのヘルパー関数を提供し、
 *   これによりコードの重複を減らし、将来の変更や拡張を容易にします。
 *
 * 使用される主な技術・ライブラリ:
 * - JavaScript: このファイルはJavaScriptで記述されています。
 */

// ユーザータイプとサービスオプションの定義
export const authorityOptions = [
  { value: 'individual', label: '個人ユーザー' },
  { value: 'corporate', label: '企業ユーザー' },
];

export const serviceOptions = [
  { value: 'all', label: 'すべて' },
  { value: 'gptdex', label: 'GPTdex' },
  { value: 'docdex', label: 'Docdex' },
];

// ラベルを取得するヘルパー関数
export const getAuthorityLabel = (authority) => {
  const option = authorityOptions.find((opt) => opt.value === authority);
  return option ? option.label : authority;
};

export const getServiceLabel = (service) => {
  const option = serviceOptions.find((opt) => opt.value === service);
  return option ? option.label : service;
};
