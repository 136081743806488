// src/components/Logo.js

/**
 * Logoコンポーネント
 *
 * アプリケーションのロゴを表示するコンポーネントです。
 * ロゴ画像のパスはプロパティで渡します。
 */

import React from 'react';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import logo from '../../assets/images/logo.png';

const logoStyle = css`
  width: 140px; // ロゴのサイズを調整
  margin-bottom: 10px; // ロゴ下の余白
`;

const Logo = ({ alt, style, onClick, ...props }) => {
  return <img src={logo} alt={alt} css={[logoStyle, style]} onClick={onClick} {...props} />;
};

Logo.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

export default Logo;
