// src/components/NavigationBar.js

import React, { useState, useEffect } from 'react';
import { css } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'; // Redux の useSelector と useDispatch フックをインポート
import Button from '../shared/button/Button';
import Logo from './Logo';
import { adminLogout } from '../../actions/adminSession'; // adminLogout アクションをインポート
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes, faUsers, faChevronRight, faSignInAlt, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'; // FontAwesomeのアイコンをインポート

const NavigationBar = () => {
  // メニューが開いているかの状態
  const [isMenuOpen, setMenuOpen] = useState(false);
  // Redux の useDispatchフック
  const dispatch = useDispatch();
  // Redux ストアからログイン状態を取得
  const isAdminAuthenticated = useSelector((state) => state.adminSession.isAdminAuthenticated);

  // メニュー外クリック時にメニューを閉じるための副作用
  useEffect(() => {
    const closeMenu = (event) => {
      // クリックされた要素がナビゲーションバーの外であり、かつメニューが開いている場合
      if (!event.target.closest('#navbar') && isMenuOpen) {
        setMenuOpen(false); // メニューを閉じる
      }
    };

    // クリックイベントリスナーをドキュメントに追加
    document.addEventListener('click', closeMenu);
    return () => {
      // コンポーネントのクリーンアップ時にイベントリスナーを削除
      document.removeEventListener('click', closeMenu);
    };
  }, [isMenuOpen]); // isMenuOpenが変更されるたびに実行

  // useNavigate フックを使用して navigate 関数を取得
  const navigate = useNavigate();

  // メニューの項目がクリックされたときのハンドラ
  const handleMenuItemClick = (e, content, categoryId = null) => {
    e.preventDefault();
    e.stopPropagation();

    // content に応じて異なるページにナビゲーション
    if (content === 'home') {
      setMenuOpen(false); // メニューを閉じる
      navigate('/'); // ホームページに遷移
    } else if (content === 'login') {
      setMenuOpen(false); // メニューを閉じる
      navigate('/login'); // ログインページに遷移
    } else if (content === 'logout') {
      setMenuOpen(false); // メニューを閉じる
      dispatch(adminLogout()); // Reduxのログアウトアクションを実行
      navigate('/login'); // ログインページに遷移
    } else if (content === 'admin-users') {
      setMenuOpen(false); // メニューを閉じる
      navigate('/admin-users'); // 管理者管理ページに遷移
    } else if (content === 'users') {
      setMenuOpen(false); // メニューを閉じる
      navigate('/users'); // ユーザー管理ページに遷移
    }
  };

  // メニュートグルのハンドラ
  const handleMenuToggle = () => {
    setMenuOpen(!isMenuOpen); // メニューの開閉状態をトグル
  };

  // ロゴクリック時のハンドラ
  const handleLogoClick = () => {
    navigate('/'); // ホームページに遷移
  };

  // ナビゲーションバーのレンダリング
  return (
    <nav css={styles.navBar} id="navbar">
      <Logo alt="GPTdex Tax Logo" style={[styles.logoStyle, styles.logoHover]} onClick={handleLogoClick} />
      {/* メニュー開閉ボタン */}
      <Button
        variant="icon"
        onClick={handleMenuToggle}
        icon={<FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars} css={styles.faIcon} />}
      />
      {/* メニューが開いている場合にメニュー内容を表示 */}
      {isMenuOpen && (
        <div css={styles.navMenu}>
          <Button
            variant="menu"
            onClick={(e) => handleMenuItemClick(e, 'home')}
            icon={<FontAwesomeIcon icon={faUsers} />}
            rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
          >
            ホーム
          </Button>
          {isAdminAuthenticated ? (
            // ログイン済みの場合、ログアウトメニューを表示
            <Button
              variant="menu"
              onClick={(e) => handleMenuItemClick(e, 'logout')}
              icon={<FontAwesomeIcon icon={faSignOutAlt} />}
            >
              ログアウト
            </Button>
          ) : (
            // 未ログインの場合、ログインメニューを表示
            <Button
              variant="menu"
              onClick={(e) => handleMenuItemClick(e, 'login')}
              icon={<FontAwesomeIcon icon={faSignInAlt} />}
            >
              ログイン
            </Button>
          )}
          <Button
            variant="menu"
            onClick={(e) => handleMenuItemClick(e, 'admin-users')}
            icon={<FontAwesomeIcon icon={faUsers} />}
            rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
          >
            管理者管理
          </Button>
          <Button
            variant="menu"
            onClick={(e) => handleMenuItemClick(e, 'users')}
            icon={<FontAwesomeIcon icon={faUsers} />}
            rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
          >
            ユーザー管理
          </Button>
        </div>
      )}
    </nav>
  );
};

const styles = {
  navBar: css`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    display: flex;
    justify-content: space-between; // 要素を左右両端に配置
    align-items: center; // 中央揃え
    padding: 0 20px;
    background-color: transparent;
  `,
  logoStyle: css`
    width: 50px;
    margin-top: 15px;
    margin-bottom: 15px;
    transition: opacity 0.3s; // 透明度の変化にトランジションを追加
    cursor: pointer;
  `,
  logoHover: css`
    &:hover {
      opacity: 0.5; // ホバー時に透明度を変更
    }
  `,
  menuIcon: css`
    cursor: pointer;
    font-size: 24px;
    color: white; /* アイコンの色 */
  `,
  navMenu: css`
    position: absolute;
    top: 100%;
    right: 0;
    padding: 10px 10px;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    width: 350px; /* メニューの幅を広げる */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    z-index: 1000; /* 他の要素の上に表示 */
  `,
  navItem: css`
    padding: 15px;
    color: #16325c;
    text-decoration: none;
    font-size: 16px;
    border-bottom: 1px solid #ddd;
    &:hover {
      background-color: #f4f4f4; /* ホバー時の背景色 */
    }
    &:last-child {
      border-bottom: none;
    }
  `,
  faIcon: css`
    color: #16325c; // FontAwesomeアイコンの色をSalesforceのメニューに合わせます
    font-size: 1.25rem; // アイコンのサイズを調整
  `,
};

export default NavigationBar;
