/**
 * UserManagementPage コンポーネント
 *
 * このコンポーネントは、通常ユーザーの管理画面を提供します。通常ユーザーの一覧表示、
 * 新規ユーザーの追加、および既存ユーザーの削除機能を含みます。
 *
 * 主な機能:
 * - 通常ユーザーの一覧表示: 登録されているユーザーの情報（ID、ユーザー名、アクティブ状態、
 *   作成日時、更新日時、ユーザータイプ、サービス区分）をテーブル形式で表示します。
 * - 新規ユーザーの追加: 「新規追加」ボタンをクリックすると、新しいユーザーを追加するためのモーダルが表示され、
 *   ユーザー名、パスワード、ユーザータイプ、サービス区分を入力して新規ユーザーを登録できます。
 * - ユーザーの削除: 各ユーザーの左側にあるチェックボックスを使用してユーザーを選択し、「削除」ボタンをクリックすると、
 *   選択したユーザーを削除できます。
 *
 * 使用される主な技術・ライブラリ:
 * - React, Redux, Emotion, react-router-dom, axios
 */

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { css } from '@emotion/react';
import NavigationBar from '../components/internal/NavigationBar';
import Typography from '../components/shared/Typography';
import Button from '../components/shared/button/Button';
import Logo from '../components/internal/Logo';
import { getUsers, registerUser, deleteUser, editUser, resetPassword } from '../actions/userRegistration'; // 適切なアクションをインポート
import UserRegistrationModal from '../components/user_management/UserRegistrationModal'; // UserRegistrationModalをインポート
import { getAuthorityLabel, getServiceLabel } from '../utils/userOptions';

function UserManagementPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAdminAuthenticated = useSelector((state) => state.adminSession.isAdminAuthenticated); // 認証状態を取得
  const users = useSelector((state) => state.userRegistration.users); // ユーザーの一覧を取得
  const [selectedUserIds, setSelectedUserIds] = useState(new Set());
  const [showRegistrationModal, setShowRegistrationModal] = useState(false);
  const [modalMode, setModalMode] = useState(null); // 'register', 'detail', 'edit' のいずれか

  useEffect(() => {
    if (!isAdminAuthenticated) {
      navigate('/login');
    } else {
      dispatch(getUsers());
    }
  }, [isAdminAuthenticated, navigate, dispatch]);

  // チェックボックスの状態を管理するハンドラ
  const handleCheckboxChange = (userId) => {
    setSelectedUserIds((prevSelectedUserIds) => {
      const newSelectedUserIds = new Set(prevSelectedUserIds);
      if (newSelectedUserIds.has(userId)) {
        newSelectedUserIds.delete(userId);
      } else {
        newSelectedUserIds.add(userId);
      }
      return newSelectedUserIds;
    });
  };

  // 新規追加モーダルを表示
  const handleShowRegistrationModal = () => {
    setModalMode('register');
    setShowRegistrationModal(true);
  };

  // 詳細モーダルを表示
  const handleShowDetailModal = () => {
    if (selectedUserIds.size === 0) {
      alert('詳細表示するユーザーを選択してください。');
      return;
    }
    if (selectedUserIds.size > 1) {
      alert('複数のユーザーを選択することはできません。');
      return;
    }
    setModalMode('detail');
    setShowRegistrationModal(true);
  };

  // 編集モーダルを表示
  const handleShowEditModal = () => {
    if (selectedUserIds.size === 0) {
      alert('編集するユーザーを選択してください。');
      return;
    }
    if (selectedUserIds.size > 1) {
      alert('複数のユーザーを選択することはできません。');
      return;
    }
    setModalMode('edit');
    setShowRegistrationModal(true);
  };

  // パスワードリセットモーダルを表示
  const handleShowResetModal = () => {
    if (selectedUserIds.size === 0) {
      alert('パスワードリセットするユーザーを選択してください。');
      return;
    }
    if (selectedUserIds.size > 1) {
      alert('複数のユーザーを選択することはできません。');
      return;
    }
    setModalMode('reset');
    setShowRegistrationModal(true);
  };

  // 選択されたユーザーを削除するハンドラ
  const handleDeleteSelected = () => {
    if (selectedUserIds.size === 0) {
      alert('削除するユーザーを選択してください。');
      return;
    }

    if (window.confirm('選択したユーザーを削除してもよろしいですか？')) {
      selectedUserIds.forEach((userId) => {
        dispatch(deleteUser(userId));
      });
      setSelectedUserIds(new Set());
    }
  };

  // ユーザー一覧の表示
  const refreshUserList = () => {
    dispatch(getUsers());
  };

  return (
    <div>
      <NavigationBar />
      <div css={styles.container}>
        <Logo alt="GPTdex Logo" css={styles.logoStyle} />
        <Typography variant="title" style={styles.title}>
          ユーザー管理
        </Typography>
        <div css={styles.actions}>
          <Button variant="primary" size="minimum" textAlign="center" onClick={handleShowRegistrationModal}>
            新規追加
          </Button>
          <Button variant="primary" size="minimum" textAlign="center" onClick={handleShowDetailModal}>
            詳細
          </Button>
          <Button variant="primary" size="minimum" textAlign="center" onClick={handleShowEditModal}>
            編集
          </Button>
          <Button variant="primary" size="minimum" textAlign="center" onClick={handleShowResetModal}>
            パスワードリセット
          </Button>
          <Button variant="primary" size="minimum" textAlign="center" onClick={handleDeleteSelected}>
            削除
          </Button>
        </div>
        <div css={tableContainerStyle}>
          <table css={tableStyle}>
            <thead>
              <tr>
                <th css={styles.checkboxColumn}></th>
                <th>ID</th>
                <th>ユーザー名</th>
                <th>アクティブ</th>
                <th>権限</th>
                <th>サービス</th>
                <th>作成日時</th>
                <th>更新日時</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user.userid}>
                  <td css={styles.checkboxColumn}>
                    <input
                      type="checkbox"
                      css={checkboxStyle}
                      checked={selectedUserIds.has(user.userid)}
                      onChange={() => handleCheckboxChange(user.userid)}
                    />
                  </td>
                  <td>{user.userid}</td>
                  <td>{user.username}</td>
                  <td>{user.is_active ? 'はい' : 'いいえ'}</td>
                  <td>{getAuthorityLabel(user.authority)}</td>
                  <td>{getServiceLabel(user.service)}</td>
                  <td>{user.created_at}</td>
                  <td>{user.updated_at}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {showRegistrationModal && (
        <UserRegistrationModal
          isOpen={showRegistrationModal}
          onClose={() => setShowRegistrationModal(false)}
          onSubmit={(username, password, authority, service, userId) => {
            return new Promise((resolve, reject) => {
              if (modalMode === 'register') {
                // 新規追加の処理
                dispatch(registerUser(username, password, authority, service))
                  .then((response) => {
                    setShowRegistrationModal(false); // 成功時にモーダルを閉じる
                    refreshUserList(); // ユーザー一覧を更新
                    resolve(response);
                  })
                  .catch((error) => {
                    reject(error);
                  });
              } else if (modalMode === 'edit') {
                // 編集の処理
                dispatch(editUser(username, password, authority, service, userId))
                  .then((response) => {
                    setShowRegistrationModal(false); // 成功時にモーダルを閉じる
                    refreshUserList(); // ユーザー一覧を更新
                    resolve(response);
                  })
                  .catch((error) => {
                    reject(error);
                  });
              } else if (modalMode === 'reset') {
                // 編集の処理
                dispatch(resetPassword(userId, password))
                  .then((response) => {
                    setShowRegistrationModal(false); // 成功時にモーダルを閉じる
                    refreshUserList(); // ユーザー一覧を更新
                    resolve(response);
                  })
                  .catch((error) => {
                    reject(error);
                  });
              }
            });
          }}
          userId={modalMode === 'register' ? null : selectedUserIds.values().next().value}
          mode={modalMode}
        />
      )}
    </div>
  );
}

export default UserManagementPage;

// スタイルの定義
const styles = {
  container: css`
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  title: css`
    margin-bottom: 40px;
  `,
  actions: css`
    display: flex;
    gap: 10px;
    width: 100%;
    margin-bottom: 20px;
    justify-content: flex-start;
  `,
  checkboxColumn: css`
    width: 50px; // チェックボックスカラムの幅を設定
  `,
  // その他の必要なスタイル
};

const tableContainerStyle = css`
  overflow-x: auto; // コンテナの幅を超えるテーブルに対して水平スクロールバーを表示
  margin: 20px 0;
  width: 100%;
`;

const tableStyle = css`
  min-width: 1200px;
  border-collapse: collapse;
  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
    &:nth-child(1) {
      // チェックボックス
      width: 40px;
      text-align: center;
    }
    &:nth-child(2) {
      // ID
      width: 40px;
    }
    &:nth-child(3) {
      // ユーザー名
      width: 160px;
    }
    &:nth-child(4) {
      // アクティブ
      width: 80px;
    }
    &:nth-child(5),
    &:nth-child(6) {
      // 権限、サービス
      width: 80px;
    }
    &:nth-child(7),
    &:nth-child(8) {
      // 作成日時、更新日時の列
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100px;
      width: 100px;
    }
  }
  th {
    background-color: #f8f8f8;
  }
  tr:hover {
    background-color: #f2f2f2;
  }
`;

const checkboxStyle = css`
  transform: scale(1.5);
  cursor: pointer; // チェックボックスにカーソルが乗ったときのカーソルを指にする
`;
