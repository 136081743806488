/**
 * src/reducers/adminSession.js
 *
 * このファイルは、管理者セッションに関連するReduxのリデューサーを定義します。
 * リデューサーは、アクションのタイプに基づいてアプリケーションのステートを更新します。
 *
 * 以下のステート更新が含まれます：
 *   - 管理者ログイン成功・失敗
 *   - 管理者ログアウト
 *   - アクセストークンのリフレッシュ成功
 *   - 管理者プロファイル情報の取得成功・失敗
 *   - トークンの有効性確認成功・失敗
 *
 * ステートは初期状態としてログイン情報、アクセストークン、リフレッシュトークン、管理者ユーザー名、ID、エラーメッセージを保持します。
 */

import {
  ADMIN_LOGIN_SUCCESS,
  ADMIN_LOGIN_FAILURE,
  ADMIN_LOGOUT,
  ADMIN_REFRESH_TOKEN_SUCCESS,
  ADMIN_PROFILE_SUCCESS,
  ADMIN_PROFILE_FAILURE,
  VALIDATE_TOKEN_SUCCESS,
  VALIDATE_TOKEN_FAILURE,
} from '../actions/types';

const initialState = {
  adminAccessToken: localStorage.getItem('gptdexAdminAccessToken'),
  adminRefreshToken: localStorage.getItem('gptdexAdminRefreshToken'),
  isAdminAuthenticated: Boolean(localStorage.getItem('gptdexAdminAccessToken')),
  adminUserName: null,
  adminId: null,
  adminError: null,
};

function adminSessionReducer(state = initialState, action) {
  switch (action.type) {
    case ADMIN_LOGIN_SUCCESS:
      return {
        ...state,
        isAdminAuthenticated: true,
        adminAccessToken: action.payload.access_token,
        adminRefreshToken: action.payload.refresh_token,
        adminUserName: action.payload.username,
        adminId: action.payload.userid,
        adminError: null,
      };
    case ADMIN_LOGIN_FAILURE:
      return {
        ...state,
        isAdminAuthenticated: false,
        adminError: action.payload,
      };
    case ADMIN_LOGOUT:
      return {
        ...state,
        isAdminAuthenticated: false,
        adminAccessToken: null,
        adminRefreshToken: null,
        adminUserName: null,
        adminId: null,
        adminError: null,
      };
    case ADMIN_REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        adminAccessToken: action.payload,
      };
    case ADMIN_PROFILE_SUCCESS:
      return {
        ...state,
        adminUserName: action.payload.username,
        adminId: action.payload.userid,
        adminError: null,
      };
    case ADMIN_PROFILE_FAILURE:
      return {
        ...state,
        adminUserName: null,
        adminId: null,
        adminError: action.payload,
      };
    case VALIDATE_TOKEN_SUCCESS:
      return state; // トークンが有効な場合はステートを変更せず
    case VALIDATE_TOKEN_FAILURE:
      return {
        ...state,
        adminError: action.payload,
      };

    default:
      return state;
  }
}

export default adminSessionReducer;
