// src/pages/DashboardPage.js

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';
import Logo from '../components/internal/Logo'; // Logoコンポーネントをインポート
import Typography from '../components/shared/Typography'; // Typographyコンポーネントをインポート
import NavigationBar from '../components/internal/NavigationBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

function DashboardPage() {
  const navigate = useNavigate();
  const isAdminAuthenticated = useSelector((state) => state.adminSession.isAdminAuthenticated);

  // ログイン状態をチェックし、未ログインの場合はログインページにリダイレクト
  useEffect(() => {
    if (!isAdminAuthenticated) {
      navigate('/login');
    }
  }, [isAdminAuthenticated, navigate]);

  return (
    <div>
      <NavigationBar />
      <div css={styles.dashboardContainer}>
        <Logo alt="GPTdex Logo" css={styles.logoStyle} />
        <Typography variant="title" style={styles.dashboardTitle}>
          AI税務相談
        </Typography>
        <Typography variant="subtitle" style={styles.dashboardSubtitle}>
          管理者ダッシュボード
        </Typography>
        <div css={styles.navigation}>
          {/* 各ナビゲーションアイテム */}
          <Link to="/admin-users" css={styles.navItem}>
            <FontAwesomeIcon icon={faUsers} css={styles.navIcon} />
            <Typography variant="text">管理者管理</Typography>
          </Link>
          <Link to="/users" css={styles.navItem}>
            <FontAwesomeIcon icon={faUsers} css={styles.navIcon} />
            <Typography variant="text">ユーザー管理</Typography>
          </Link>
          <Link to="/qa/home" css={styles.navItem}>
            <FontAwesomeIcon icon={faQuestionCircle} css={styles.navIcon} />
            <Typography variant="text">QA管理</Typography>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default DashboardPage;

// Emotionでのスタイル定義
const styles = {
  dashboardContainer: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    height: 100vh;
  `,
  logoStyle: css`
    width: 140px;
    margin-bottom: 30px;
  `,
  dashboardTitle: css`
    margin-bottom: 8px;
  `,
  dashboardSubtitle: css`
    margin-bottom: 24px;
  `,
  navigation: css`
    display: grid;
    grid-template-columns: repeat(3, 1fr); // 3つのカラムを持つグリッドに変更
    gap: 30px;
    justify-content: center;
    margin-bottom: 30px;
  `,
  navItem: css`
    background-color: white; // カードの背景色を設定
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px; // 角の丸みを増やす
    text-decoration: none;
    color: #003366;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // カードに影を追加
    transition: transform 0.3s, box-shadow 0.3s; // アニメーション効果を追加
    max-width: 180px;
    text-align: center;

    &:hover {
      transform: translateY(-5px); // ホバー時にカードを少し上に動かす
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); // 影を大きくする
    }
  `,
  navIcon: css`
    font-size: 40px;
    margin-bottom: 5px;
  `,
  navText: css`
    font-size: 16px;
  `,
};
