// src/store.js

import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import adminSessionReducer from './reducers/adminSession';
import adminUserRegistrationReducer from './reducers/adminUserRegistration';
import userRegistrationReducer from './reducers/userRegistration';

const reducer = {
  adminSession: adminSessionReducer,
  adminUserRegistration: adminUserRegistrationReducer,
  userRegistration: userRegistrationReducer,
};

const initialState = {};

const middleware = [thunk];

const store = configureStore({
  reducer,
  preloadedState: initialState,
  middleware,
  devTools: process.env.NODE_ENV !== 'production',
});

export default store;
