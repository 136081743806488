/**
 * src/actions/userRegistration.js
 *
 * このファイルは、ユーザーに関連するReduxのアクションを定義します。
 * 主に以下の機能を提供します：
 *   - ユーザー一覧の取得
 *   - 新規ユーザーの登録
 *   - 既存ユーザーの削除
 *
 * 各アクションは非同期のAPIリクエストを行い、その結果に基づいてReduxのステートを更新します。
 * APIエンドポイントのURLは環境変数から取得し、適切なHTTPリクエストを実行します。
 */

import axios from 'axios';
import {
  GET_USERS_SUCCESS,
  GET_USERS_FAILURE,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAILURE,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAILURE,
  RESET_USER_PASSWORD_SUCCESS,
  RESET_USER_PASSWORD_FAILURE,
} from './types';

// 通常ユーザー一覧の取得
export const getUsers = () => async (dispatch, getState) => {
  const { adminAccessToken } = getState().adminSession;
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/auth/user_registration_admin_managed/get_users`,
      {
        headers: { Authorization: `Bearer ${adminAccessToken}` },
      }
    );
    dispatch({
      type: GET_USERS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: GET_USERS_FAILURE,
      payload: error.response.data.message,
    });
  }
};

// 新規ユーザーの登録
export const registerUser =
  (username, password, authority, service = 'all') =>
  async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      const { adminAccessToken } = getState().adminSession;
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/auth/user_registration_admin_managed/register_user`,
          { username, password, authority, service },
          { headers: { Authorization: `Bearer ${adminAccessToken}` } }
        );
        dispatch({
          type: REGISTER_USER_SUCCESS,
          payload: response.data,
        });
        resolve(response.data);
      } catch (error) {
        dispatch({
          type: REGISTER_USER_FAILURE,
          payload: error.response.data.message,
        });
        reject(error.response.data.message);
      }
    });
  };

// ユーザーの削除
export const deleteUser = (userId) => async (dispatch, getState) => {
  const { adminAccessToken } = getState().adminSession;
  try {
    await axios.delete(`${process.env.REACT_APP_API_URL}/auth/user_registration_admin_managed/delete_user`, {
      headers: { Authorization: `Bearer ${adminAccessToken}` },
      data: { userid: userId },
    });
    dispatch({
      type: DELETE_USER_SUCCESS,
      payload: userId,
    });
  } catch (error) {
    dispatch({
      type: DELETE_USER_FAILURE,
      payload: error.response.data.message,
    });
  }
};

// 既存ユーザーの編集
export const editUser = (username, password, authority, service, userId) => async (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    const { adminAccessToken } = getState().adminSession;
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/auth/user_registration_admin_managed/edit_user/${userId}`,
        { username, password, authority, service },
        { headers: { Authorization: `Bearer ${adminAccessToken}` } }
      );
      dispatch({
        type: EDIT_USER_SUCCESS,
        payload: response.data,
      });
      resolve(response.data);
    } catch (error) {
      dispatch({
        type: EDIT_USER_FAILURE,
        payload: error.response.data.message,
      });
      reject(error.response.data.message);
    }
  });
};

// パスワードリセットアクション
export const resetPassword = (userId, newPassword) => async (dispatch, getState) => {
  const { adminAccessToken } = getState().adminSession;
  try {
    await axios.post(
      `${process.env.REACT_APP_API_URL}/auth/user_registration_admin_managed/reset_password`,
      { userid: userId, password: newPassword },
      { headers: { Authorization: `Bearer ${adminAccessToken}` } }
    );
    dispatch({
      type: RESET_USER_PASSWORD_SUCCESS,
      payload: userId, // 成功した場合、ユーザーIDをペイロードに含める
    });
  } catch (error) {
    dispatch({
      type: RESET_USER_PASSWORD_FAILURE,
      payload: error.response.data.message,
    });
  }
};
