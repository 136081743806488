/**
 * UserRegistrationModal コンポーネント
 *
 * このコンポーネントは、ユーザーの追加、詳細表示、編集のためのモーダルを提供します。
 * モード（'registration', 'detail', 'edit'）に応じて、新しいユーザーの追加、既存ユーザーの詳細表示、
 * および編集が行えます。
 *
 * 主な機能:
 * - ユーザー情報フォーム: ユーザー名、パスワード、ユーザータイプ、サービス区分の入力フィールドを提供し、
 *   ユーザー情報の追加や編集を行います。詳細表示モードでは、これらのフィールドは読み取り専用です。
 * - エラーメッセージの表示: 入力エラーやサーバー側の問題が発生した場合、
 *   ユーザーにエラーメッセージを表示します。
 * - モーダルの開閉機能: ユーザーは「×」ボタンをクリックすることでモーダルを閉じることができます。
 * - 動的なフォーム制御: モードに応じてフォームの入力可能状態やボタンのラベルが変更されます。
 *
 * Props:
 * - isOpen: モーダルの表示状態を制御します。
 * - onClose: モーダルを閉じるための関数です。
 * - onSubmit: フォームの送信時に呼び出される関数です。
 * - userId: 編集または詳細表示するユーザーのIDです（新規追加時はnull）。
 * - title: モーダルのタイトルです。
 * - submitLabel: 提出ボタンのラベルです。
 * - isEditable: フォームが編集可能かどうかを示します。
 *
 * 使用される主な技術・ライブラリ:
 * - React, Emotion, react-modal
 */

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Modal from 'react-modal';
import { css } from '@emotion/react';
import Button from '../shared/button/Button';
import Input from '../shared/Input';
import Form from '../shared/Form';
import Logo from '../internal/Logo';
import Typography from '../shared/Typography';
import Dropdown from '../shared/Dropdown';
import { authorityOptions, serviceOptions } from '../../utils/userOptions';

function UserRegistrationModal({ isOpen, onClose, onSubmit, userId, mode }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [authority, setAuthority] = useState('individual');
  const [service, setService] = useState('all');
  const [error, setError] = useState('');
  const users = useSelector((state) => state.userRegistration.users);
  const [subTitle, setSubTitle] = useState('');
  const [submitLabel, setSubmitLabel] = useState('');
  const [usernameReadOnly, setUsernameReadOnly] = useState(false);
  const [passwordVisible, setPassworVisible] = useState(false);
  const [authorityReadOnly, setAuthorityReadOnly] = useState(false);
  const [serviceReadOnly, setServiceReadOnly] = useState(false);
  const [submitButtonVisible, setSubmitButtonVisible] = useState(false);

  useEffect(() => {
    if (mode === 'register') {
      setSubTitle('新規ユーザー追加');
      setSubmitLabel('追加');
      setUsernameReadOnly(false);
      setPassworVisible(true);
      setAuthorityReadOnly(false);
      setServiceReadOnly(false);
      setSubmitButtonVisible(true);
    } else if (mode === 'detail') {
      setSubTitle('ユーザー詳細');
      setSubmitLabel('');
      setUsernameReadOnly(true);
      setPassworVisible(false);
      setAuthorityReadOnly(true);
      setServiceReadOnly(true);
      setSubmitButtonVisible(false);
    } else if (mode === 'edit') {
      setSubTitle('ユーザー編集');
      setSubmitLabel('編集');
      setUsernameReadOnly(false);
      setPassworVisible(false);
      setAuthorityReadOnly(false);
      setServiceReadOnly(false);
      setSubmitButtonVisible(true);
    } else if (mode === 'reset') {
      setSubTitle('パスワードリセット');
      setSubmitLabel('リセット');
      setUsernameReadOnly(true);
      setPassworVisible(true);
      setAuthorityReadOnly(true);
      setServiceReadOnly(true);
      setSubmitButtonVisible(true);
    }
    if (userId != null) {
      const numericUserId = parseInt(userId, 10);
      const user = users.find((user) => user.userid === numericUserId);
      if (user) {
        setUsername(user.username);
        setPassword(''); // パスワードは表示しない
        setAuthority(user.authority);
        setService(user.service);
      }
    } else {
      resetForm();
    }
  }, [userId, users, mode]);

  const resetForm = () => {
    setUsername('');
    setPassword('');
    setAuthority('individual');
    setService('all');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(username, password, authority, service, userId)
      .then(() => resetForm())
      .catch((error) => setError(error));
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} style={modalStyle}>
      <Form style={formStyle} onSubmit={handleSubmit}>
        <Logo alt="GPTdex Logo" style={logoStyle} />
        <Button variant="close" css={closeButtonStyle} onClick={onClose}>
          ×
        </Button>
        <Typography variant="subtitle">{subTitle}</Typography>
        {error && <p css={errorMessageStyle}>{error}</p>}
        <Input
          readOnly={usernameReadOnly}
          style={inputStyle}
          type="text"
          placeholder="ユーザー名"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        {passwordVisible && (
          <Input
            style={inputStyle}
            type="password"
            placeholder="パスワード"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        )}
        <Dropdown
          disabled={authorityReadOnly}
          styles={selectStyle}
          options={authorityOptions}
          selectedValue={authority}
          onChange={(e) => setAuthority(e.target.value)}
        />
        <Dropdown
          disabled={serviceReadOnly}
          styles={selectStyle}
          options={serviceOptions}
          selectedValue={service}
          onChange={(e) => setService(e.target.value)}
        />
        {submitButtonVisible && (
          <Button type="submit" variant="primary" size="large" style={submitButtonStyle}>
            {submitLabel}
          </Button>
        )}
      </Form>
    </Modal>
  );
}

export default UserRegistrationModal;

// Emotionでのスタイル定義
const modalStyle = {
  content: {
    width: '90%', // モバイル時の横幅
    maxWidth: '500px', // モーダルの最大横幅
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, 0)',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    padding: '40px 10px',
    zIndex: '1001',
  },
};

const formStyle = css`
  box-shadow: none;
  padding: 0;
  @media (max-width: 400px) {
    padding: 0 20px;
  }
`;

const logoStyle = css`
  width: 100px;
  margin-bottom: 0;
`;

const inputStyle = css`
  min-width: auto;
  width: 70%;
  @media (max-width: 450px) {
    width: 90%;
  }
`;

const selectStyle = css`
  min-width: auto;
  width: calc(70% + 20px);
  @media (max-width: 768px) {
    width: calc(70% + 20px);
  }
  @media (max-width: 450px) {
    width: calc(90% + 20px);
  }
`;

const submitButtonStyle = css`
  justify-content: center;
  min-width: auto;
  width: calc(70% + 20px);
  @media (max-width: 450px) {
    width: calc(90% + 20px);
  }
`;

const closeButtonStyle = css`
  position: absolute;
  top: 20px;
  right: 20px;
`;

const errorMessageStyle = css`
  color: red;
  text-align: center;
  margin: 0;
`;
